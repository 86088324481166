<template>
  <div>
    <header>
      <div id="inside">
        <h1>Komfortowy sen w mroźne i upalne dni</h1>
        <h2>
          Dzięki zastosowaniu piany poliuretanowej, która posiada doskonałe
          właściwości fizyczne i ekologiczne gwarantując 100% szczelności.
        </h2>
      </div>
    </header>
    <section id="aboutus">
      <div id="inside">
        <h1>POZNAJ NAS I NASZE DOŚWIADCZENIE</h1>
        <h2>
          Purtech Izolacje - natryskowe ocieplanie pianką poliuretanową
          poddaszy, dachów, hal, budynków gospodarczych, magazynów oraz
          hydroizolacje.
        </h2>
        <h3>
          W 2011 roku Purtech rozpoczął swoją działalność w technologii
          docieplenia budynków poprzez natrysk piany poliuretanowej w ramach
          struktury Purtech Poland sp. z o.o. Pierwsze doświadczenia w natrysku
          piany PUR zdobywaliśmy na materiałach firm LaPolla, Icynene, Synthesia
          Espaniola, Honter, Bayer i BASF. Mając szeroką wiedzę o dostępnych
          komponentach na rynku polskim podjęliśmy decyzję o poszukiwaniu nowych
          komponentów na rynku amerykańskim<br /><br />Po przeprowadzeniu
          pierwszych testów oraz badań kilku marek pian otwartokomórowych,
          został wybrany najlepszy materiał w atrakcyjnej cenie. Materiał został
          przebadany przez Państwowy Instytut Techniki Budowlanej i otrzymał
          wszystkie niezbędne dokumenty do obrotu na terenie Unii Europejskiej.
          <br /><br />W lutym 2016r Purtech Izolacje jako dział wykonawstwa
          usług aplikacji piany odłączył się od Purtech Poland i rozpoczął
          samodzielną działalność. <br /><br />Nasze ponad 10-letnie
          doświadczenie w aplikacji różnych materiałów pozwala uzyskiwać
          najwyższą jakość izolacji.
        </h3>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
header {
  width: 100%;
  height: 80vh;
  min-height: 540px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("../assets/images/header.jpg");
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0%, 100% 95%, 0 100%);
}

header div#inside {
  width: 60%;
  color: #fff;
}

header div#inside h1 {
  font-size: 2rem;
  font-weight: 600;
  margin: 160px 0 40px 0;
}

header div#inside h2 {
  width: 50%;
  margin: 0 0 0 10px;
  font-size: 1rem;
  text-align: left;
  line-height: 1.8rem;
}

section#aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 60px 0;
}

section#aboutus div#inside {
  width: 60%;
}

section#aboutus div#inside h1 {
  font-size: 1.4rem;
  color: #6ba421;
  margin: 0 0 30px 0;
  font-weight: 600;
}

section#aboutus div#inside h2 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 30px 0;
  font-weight: 600;
  width: 70%;
}

section#aboutus div#inside h3 {
  font-size: 1rem;
  margin: 0 0 30px 0;
  width: 70%;
  line-height: 1.5rem;
  text-align: justify;
}

@media only screen and (max-width: 1280px) {
  header div#inside {
    width: 80%;
  }

  header div#inside h1 {
    font-size: 1.6rem;
    margin: 80px 0 40px 0;
  }

  header div#inside h2 {
    width: 90%;
  }

  section#aboutus div#inside {
    width: 80%;
  }

  section#aboutus div#inside h2 {
    width: 100%;
    font-size: 1.6rem;
  }

  section#aboutus div#inside h3 {
    width: 100%;
  }
}
</style>
